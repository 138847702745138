var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"devoluciones mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Devoluciones ")]),_c('v-card-subtitle',[_vm._v(" Desde este apartado podrás ver el registro de devoluciones de tus productos. ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha inicial","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_1),callback:function ($$v) {_vm.menu_1=$$v},expression:"menu_1"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu_1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha final","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_2),callback:function ($$v) {_vm.menu_2=$$v},expression:"menu_2"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu_2 = false}},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v("Servicios")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers_s,"search":_vm.search_servicios,"items":_vm.servicios,"footer-props":{ itemsPerPageText: 'Servicios' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_servicios),callback:function ($$v) {_vm.search_servicios=$$v},expression:"search_servicios"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"black","dark":""},on:{"click":function($event){return _vm.download(0)}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1)],1)],1)]},proxy:true},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.precio))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',[_vm._v("Total")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.totalizacion.servicios.cantidad)+" ")]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totalizacion.servicios.total)))])])]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v("Productos de venta")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"search":_vm.search_ventas,"items":_vm.ventas,"footer-props":{ itemsPerPageText: 'productos' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_ventas),callback:function ($$v) {_vm.search_ventas=$$v},expression:"search_ventas"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"black","dark":""},on:{"click":function($event){return _vm.download(1)}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1)],1)],1)]},proxy:true},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.precio))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v("Total")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.totalizacion.ventas.cantidad)+" ")]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totalizacion.ventas.total)))])])]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v("Productos de consumo")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"search":_vm.search_consumos,"items":_vm.consumos,"footer-props":{ itemsPerPageText: 'productos' }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_consumos),callback:function ($$v) {_vm.search_consumos=$$v},expression:"search_consumos"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"black","dark":""},on:{"click":function($event){return _vm.download(2)}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Descargar ")],1)],1)],1)],1)]},proxy:true},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.precio))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v("Total")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.totalizacion.consumos.cantidad)+" ")]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totalizacion.consumos.total)))])])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }