<template>
  <div class="devoluciones mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>
            Devoluciones
          </v-card-title>
          <v-card-subtitle>
            Desde este apartado podrás ver el registro de devoluciones de tus
            productos.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- Servicios -->
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-title>Servicios</v-card-title>
                  <v-card-text>
                    <v-data-table
                      dense
                      :headers="headers_s"
                      :search="search_servicios"
                      :items="servicios"
                      :footer-props="{ itemsPerPageText: 'Servicios' }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row dense>
                            <v-col cols="6" class="px-0">
                              <v-text-field
                                outlined
                                rounded
                                dense
                                append-icon="mdi-magnify"
                                label="Buscar"
                                v-model="search_servicios"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-btn color="black" dark @click="download(0)">
                                <v-icon>mdi-download</v-icon>
                                Descargar
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.precio="{ item }">
                        {{ item.precio | currency }}
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                      <template v-slot:body.append>
                        <tr>
                          <th>Total</th>
                          <th colspan="2">
                            {{ totalizacion.servicios.cantidad }}
                          </th>
                          <th>{{ totalizacion.servicios.total | currency }}</th>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Ventas -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>Productos de venta</v-card-title>
                  <v-card-text>
                    <v-data-table
                      dense
                      :headers="headers"
                      :search="search_ventas"
                      :items="ventas"
                      :footer-props="{ itemsPerPageText: 'productos' }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row dense>
                            <v-col cols="6" class="px-0">
                              <v-text-field
                                outlined
                                rounded
                                dense
                                append-icon="mdi-magnify"
                                label="Buscar"
                                v-model="search_ventas"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-btn color="black" dark @click="download(1)">
                                <v-icon>mdi-download</v-icon>
                                Descargar
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.precio="{ item }">
                        {{ item.precio | currency }}
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                      <template v-slot:body.append>
                        <tr>
                          <th colspan="3">Total</th>
                          <th colspan="2">
                            {{ totalizacion.ventas.cantidad }}
                          </th>
                          <th>{{ totalizacion.ventas.total | currency }}</th>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Consumos -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>Productos de consumo</v-card-title>
                  <v-card-text>
                    <v-data-table
                      dense
                      :headers="headers"
                      :search="search_consumos"
                      :items="consumos"
                      :footer-props="{ itemsPerPageText: 'productos' }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row dense>
                            <v-col cols="6" class="px-0">
                              <v-text-field
                                outlined
                                rounded
                                dense
                                append-icon="mdi-magnify"
                                label="Buscar"
                                v-model="search_consumos"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-btn color="black" dark @click="download(2)">
                                <v-icon>mdi-download</v-icon>
                                Descargar
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.precio="{ item }">
                        {{ item.precio | currency }}
                      </template>
                      <template v-slot:item.total="{ item }">
                        {{ item.total | currency }}
                      </template>
                      <template v-slot:body.append>
                        <tr>
                          <th colspan="3">Total</th>
                          <th colspan="2">
                            {{ totalizacion.consumos.cantidad }}
                          </th>
                          <th>{{ totalizacion.consumos.total | currency }}</th>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import XLSX from "xlsx";
export default {
  data: () => ({
    date: moment().format("YYYY-MM-DD"),
    menu_1: false,
    date_fin: moment().format("YYYY-MM-DD"),
    menu_2: false,
    search_ventas: "",
    search_consumos: "",
    search_servicios: "",
    headers: [
      {
        text: "Cod sis",
        value: "sistema",
        align: "start",
        sortable: false,
      },
      {
        text: "Código",
        value: "codigo",
        sortable: false,
      },
      {
        text: "Salida",
        value: "salida",
        sortable: false,
      },
      {
        text: "Cant",
        value: "cantidad",
        sortable: false,
        filterable: false,
      },
      {
        text: "Precio Venta Promedio",
        value: "precio",
        sortable: false,
        filterable: false,
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
        filterable: false,
      },
    ],
    headers_s: [
      {
        text: "Servicio",
        value: "salida",
        sortable: false,
      },
      {
        text: "Cant",
        value: "cantidad",
        sortable: false,
        filterable: false,
      },
      {
        text: "Precio Venta Promedio",
        value: "precio",
        sortable: false,
        filterable: false,
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
        filterable: false,
      },
    ],
    devoluciones: {
      ventas: [],
      consumos: [],
      servicios: [],
    },
  }),
  methods: {
    loadDevoluciones() {
      const body = {
        route: "/devoluciones",
        params: {
          date: JSON.stringify({ inicio: this.date, fin: this.date_fin }),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.devoluciones = response.data.data;
        }
      });
    },
    download(index) {
      const names = [
        "Devoluciones-Servicios",
        "Devoluciones-Ventas",
        "Devoluciones-Consumos",
      ];
      let data;
      if (index == 0) {
        data = this.servicios.map((servicio) => {
          return {
            Columna_A: servicio.salida,
            Columna_B: servicio.cantidad,
            Columna_C: servicio.precio,
            Columna_D: servicio.total,
          };
        });
        data.unshift(
          {
            Columna_A: "Fecha inicial",
            Columna_B: moment(this.date).format("ll"),
            Columna_C: "",
            Columna_D: "",
          },
          {
            Columna_A: "Fecha final",
            Columna_B: moment(this.date_fin).format("ll"),
            Columna_C: "",
            Columna_D: "",
          },
          {
            Columna_A: "Servicio",
            Columna_B: "Cantidad",
            Columna_C: "Precio Venta Promedio",
            Columna_D: "Total",
          }
        );
      }

      if (index == 1) {
        data = this.ventas.map((venta) => {
          return {
            Columna_A: venta.sistema,
            Columna_B: venta.codigo,
            Columna_C: venta.salida,
            Columna_D: venta.cantidad,
            Columna_E: venta.precio,
            Columna_F: venta.total,
          };
        });
        data.unshift(
          {
            Columna_A: "Fecha inicial",
            Columna_B: moment(this.date).format("ll"),
            Columna_C: "",
            Columna_D: "",
          },
          {
            Columna_A: "Fecha final",
            Columna_B: moment(this.date_fin).format("ll"),
            Columna_C: "",
            Columna_D: "",
          },
          {
            Columna_A: "Cod Sis",
            Columna_B: "Codigo",
            Columna_C: "Salida",
            Columna_D: "Cantidad",
            Columna_E: "Precio Venta Promedio",
            Columna_F: "Total",
          }
        );
      }

      if (index == 2) {
        data = this.consumos.map((consumo) => {
          return {
            Columna_A: consumo.sistema,
            Columna_B: consumo.codigo,
            Columna_C: consumo.salida,
            Columna_D: consumo.cantidad,
            Columna_E: consumo.precio,
            Columna_F: consumo.total,
          };
        });
        data.unshift(
          {
            Columna_A: "Fecha inicial",
            Columna_B: moment(this.date).format("ll"),
            Columna_C: "",
            Columna_D: "",
          },
          {
            Columna_A: "Fecha final",
            Columna_B: moment(this.date_fin).format("ll"),
            Columna_C: "",
            Columna_D: "",
          },
          {
            Columna_A: "Cod Sis",
            Columna_B: "Codigo",
            Columna_C: "Salida",
            Columna_D: "Cantidad",
            Columna_E: "Precio Venta Promedio",
            Columna_F: "Total",
          }
        );
      }
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = names[index];
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  created() {
    this.loadDevoluciones();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    ventas() {
      return this.devoluciones.ventas.map((venta) => {
        return {
          sistema: venta.sistema,
          codigo: venta.codigo,
          salida: venta.salida,
          cantidad: parseFloat(venta.cantidad),
          precio: parseFloat(venta.total) / parseFloat(venta.cantidad),
          total: parseFloat(venta.total),
        };
      });
    },
    consumos() {
      return this.devoluciones.consumos.map((consumo) => {
        return {
          sistema: consumo.sistema,
          codigo: consumo.codigo,
          salida: consumo.salida,
          cantidad: parseFloat(consumo.cantidad),
          precio: parseFloat(consumo.total) / parseFloat(consumo.cantidad),
          total: parseFloat(consumo.total),
        };
      });
    },
    servicios() {
      return this.devoluciones.servicios.map((servicio) => {
        return {
          salida: servicio.salida,
          cantidad: parseFloat(servicio.cantidad),
          precio: parseFloat(servicio.total) / parseFloat(servicio.cantidad),
          total: parseFloat(servicio.total),
        };
      });
    },
    totalizacion() {
      return {
        ventas: {
          cantidad: this.ventas.reduce((index, item) => {
            return index + item.cantidad;
          }, 0),
          total: this.ventas.reduce((index, item) => {
            return index + item.total;
          }, 0),
        },
        consumos: {
          cantidad: this.consumos.reduce((index, item) => {
            return index + item.cantidad;
          }, 0),
          total: this.consumos.reduce((index, item) => {
            return index + item.total;
          }, 0),
        },
        servicios: {
          cantidad: this.servicios.reduce((index, item) => {
            return index + item.cantidad;
          }, 0),
          total: this.servicios.reduce((index, item) => {
            return index + item.total;
          }, 0),
        },
      };
    },
  },
  watch: {
    date(val) {
      this.loadDevoluciones();
      return this.date;
    },
    date_fin(val) {
      this.loadDevoluciones();
      return this.date_fin;
    },
  },
};
</script>
